import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Today from "../components/dashboard/today"
import Wallet from "../components/dashboard/wallet"
import ReportCard from "../components/dashboard/report-card"
import { getMethod, postMethod, putMethod } from "../helper/api"
import { updateBookings } from "../state/actions/user/useraction"
import { setSubject } from "../state/actions/booking/bookingAction"
import BookALesson from "../components/dashboard/book-a-lesson-component"
import Earnings from "../components/dashboard/teacher/earnings"
import Analytics from "../components/dashboard/teacher/analytics"
import BookingList from "../components/dashboard/teacher/booking-list"
import { navigate, Link } from "gatsby"
import { isBrowser, setWindowItem } from '../helper/globals';
import { Cookies } from "react-cookie"
import moment from "moment"


const Cart = ({ userDetails, location, data }) => {


    const [cart, setCart] = useState({});
    const [balancePayment, setBalancePayment] = useState(0);
    const [totalHrs, setTotalHrs] = useState(0);

    let firstname, rest, user_role;

    useEffect(() => {
        let cookie = new Cookies()
        if (!window.localStorage.getItem('token')) {
            navigate('https://app.121islamonline.com/?v='+window.localStorage.getItem('appVersion'));
        }
    }, [])
    useEffect(() => {
        if (isBrowser) {
            const params = new URLSearchParams(location.search);
            const cart_id = params.get("cart_id");

            if (cart_id === 'success') {
                window.localStorage.removeItem('cart');
                window.localStorage.removeItem('date');
                window.localStorage.removeItem('duration');
                window.localStorage.removeItem('selectedTeacher');
                window.localStorage.removeItem('days');
                window.localStorage.removeItem('enddate');
                window.localStorage.removeItem('slots');
                window.localStorage.removeItem('selectedSubject');
                window.localStorage.removeItem('level');
                window.localStorage.removeItem('startdate');
                window.localStorage.removeItem('slotType');
                window.localStorage.removeItem('selectedSubjectValues');
                window.localStorage.removeItem('state');
            }
        }
    }, []);



    useEffect(() => {


        const params = new URLSearchParams(location.search);
        const cart_id = params.get("cart_id");

        //console.log(cart_id);

        async function getMyDetails() {
            let sub2 = await getMethod(`/carts`, '', { 'id': cart_id });
            sub2.data[0]['time'] = JSON.parse(sub2.data[0]['time']);
            sub2.data[0]['timeObject'] = [sub2.data[0]['time']];
            //console.log(sub2.data[0]['timeObject']);
            setCart(sub2.data[0]);
            setWindowItem('cart',JSON.stringify(sub2.data[0]));
            //console.log(sub2.data);
        }

        getMyDetails()

    }, [firstname, userDetails])

    async function recharge(cart, balancePaymentHrs) {
        navigate("/wallet-payment", {
            state: { cart: cart, balancePaymentHrs: balancePaymentHrs },
        })
    }

    async function abortCart() {
        let resp = await postMethod('/abortcart', { cart_id: cart.id });
        navigate('/dashboard?booking=success&v='+window.localStorage.getItem('appVersion'));
    }

    async function checkOut() {
        //console.log(booking);
        //let fac = booking.slot.slots.
        const params = new URLSearchParams(location.search);
        const cart_id = params.get("cart_id");

        let cartretry = {

            user: userDetails.id,
            cart_id: cart_id
        }

        //console.log("newbooking", newbooking)

        let resp = await postMethod('/retrycartpayment', cartretry);
        if (resp.data.balance_payment_hrs > 0) {
            setBalancePayment(resp.data.balance_payment_hrs)
            setTotalHrs(resp.data.totalHrs)
        }
        else
            navigate('/dashboard?booking=success&v='+window.localStorage.getItem('appVersion'));
    }



    if (userDetails) {
        ; ({ firstname, user_role, ...rest } = userDetails)
        if (firstname === null || firstname === "") {
            //navigate('/profile?v='+window.localStorage.getItem('appVersion'));
        }
    }

    return (
        <div className="page-wrapper animated fadeInLeft">
            <Layout title="Cart">
                <Seo title="Cart" />
                <div className="row align-items-center" style={{ margin: '10px' }}>
                    <div className="col"><h6 className="mb-0"><strong>Booking Details</strong></h6></div>
                </div>
                {cart.id !== undefined && <div className="booking-details" style={{ margin: '20px' }}>
                    <div className="tutor-name">
                        <div className="row align-items-center">
                            <div className="col">
                                <b>Subject : </b>{`${cart?.subject.name}`}
                            </div>
                        </div>
                    </div>
                    <div className="tutor-name">
                        <div className="row align-items-center">
                            <div className="col">
                                <b>Duration :</b> {`${cart?.duration} minutes`}
                            </div>
                        </div>
                    </div>
                    <div className="tutor-name">
                        <div className="row align-items-center">
                            <div className="col">
                                <b>Level :</b> {`${cart?.level}`}
                            </div>
                        </div>
                    </div>
                    <div className="tutor-name">
                        <div className="row align-items-center">
                            <div className="col">
                                <b>Student :</b> {`${cart?.booked_by?.firstname} ${cart?.booked_by?.lastname}`}
                            </div>
                        </div>
                    </div>
                    <div className="tutor-name">
                        <div className="row align-items-center">
                            <div className="col">
                                <b>Tutor :</b> <i className="icon-Profile text-primary"></i>{`${cart?.faculty?.firstname} ${cart?.faculty?.lastname}`}
                            </div>

                        </div>
                    </div>

                    {cart?.timeObject?.map((d, i) => {
                        //console.log(cart)
                        let okeys = Object.keys(cart.timeObject);
                        let disp = [];
                        for (let k = 0; k < okeys.length; k++) {
                            let keys = Object.keys(cart.timeObject[okeys[k]]);
                            for (let j = 0; j < keys.length; j++) {
                                let dkeys = Object.keys(cart.timeObject[okeys[k]][keys[j]]);
                                for(let l=0; l<dkeys.length; l++){
                                    //console.log(cart.timeObject[okeys[k]][keys[j]][dkeys[l]]);
                                    disp.push(<div key={`${j}${k}${l}`} className="booking-date"><b>Slot :</b> <i className="icon-Time text-primary"></i>{moment(`${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["date"]}T${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["start_time"]}Z`).format('ddd')} {moment(`${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["date"]}T${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["start_time"]}Z`).format('DD-MM-YYYY hh:mm A')} - {moment(`${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["date"]}T${cart.timeObject[okeys[k]][keys[j]][dkeys[l]]["end_time"]}Z`).format('hh:mm A')}</div>)
                                }
                            }
                        }
                        return disp;
                    })}
                    <br />
                    <br />
                    <br />
                    <div className="row align-items-center" style={{ margin: '10px' }}>
                        <div className="col" style={{ "textAlign": 'center' }}><h6 className="mb-0" style={{ color: 'red' }}><strong>Payment Failed - Try Again</strong></h6></div>
                    </div>
                    {balancePayment > 0 && (
                        <div
                            className="btn btn-primary w-100 mb-0"
                            onClick={() => recharge({ ...cart, totalHrs }, balancePayment)}
                        >
                            Pay for {balancePayment}{" "}
                            {balancePayment > 1 ? "hrs" : "hr"} and Confirm
                        </div>
                    )}
                    {balancePayment === 0 && (
                        <div
                            className="btn btn-primary w-100 mb-0"
                            onClick={() => checkOut()}
                        >
                            Checkout
                        </div>
                    )}
                    <br />
                    
                    <br />
                    <div
                        className="btn btn-danger w-100 mb-0"
                        onClick={() => abortCart()}
                    >
                        Abort
                    </div>
                </div>}
            </Layout>
        </div>
    )
}

export default connect(
    state => ({ userDetails: state.loginReducer.authenticate.user }),
    null
)(Cart)
